import { CommonModule } from '@angular/common';
import {Component, Input, Output, EventEmitter} from '@angular/core';
import { Router, RouterModule} from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  imports: [RouterModule, CommonModule]
})
export class MenuItemComponent{

  constructor(
    protected router: Router,
  ) {
  }

  @Input() icon: string = '';
  @Input() label: string = '';
  @Input() logout: boolean = false;
  @Input() destination: string = '';
  @Input() type: 'link' | 'button' = 'link';
  @Input() countMessage : boolean = false;
  @Output() clickEvent = new EventEmitter<any>()

  onClick(): void {
    this.clickEvent.emit();
  }
}
