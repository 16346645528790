import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService} from "../../../../common/services/abstract.service";

import {ToastService} from './toast.service';
import {Router} from "@angular/router";
import {catchError} from "rxjs";
import {Constants} from "@common/config/constants";

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends AbstractService {


  constructor(
    protected http: HttpClient,
    protected constants: Constants,
    toastService: ToastService,
    router: Router
  ) {
    super(toastService, router);
  }

  protected countUnreadConversation : boolean = false

  get count() {
    return this.countUnreadConversation;
  }

  set count(count: boolean) {
    this.countUnreadConversation = count;
  }

  actualiseCount(): void {
    this.getAllUnreadConversation().subscribe({
      next: result => {
        this.countUnreadConversation = result
      }
    })
  }

  getAllUnreadConversation() {
    return this.http.get(`${this.constants.ATSAPP_API_URL}/mercure/conversations/getUnreadConv`)
      .pipe(catchError(err => this.handleError(err)));
  }

}
