import {Component, OnInit} from '@angular/core';
import {StatTypeUserEnum} from "../../../types/StatTypeUser.enum";
import {UserService} from "@common/services/user.service";
import {StatisticMenuComponent} from "../../../molecules/statistic-menu/statistic-menu.component";
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-statistic-admin-validate',
  templateUrl: './statistic-admin-validate.component.html',
  standalone: true,
  imports: [
    StatisticMenuComponent,
    ReactiveFormsModule,
    CommonModule
  ],
  styleUrls: ['./statistic-admin-validate.component.scss']
})
export class StatisticAdminValidateComponent implements OnInit{
  constructor(
    private userService: UserService,
    private fb: FormBuilder,
  ) {
  }

  typeUser: StatTypeUserEnum = StatTypeUserEnum.SPEAKER

  periodValidation = this.fb.group({
    period: ['', [Validators.required]],
  })

  test : any[] = [{
    test:'jjj',
    value:'jfkdlsm'
  }]

  displayDetail : boolean = false;

  ngOnInit() {
    if (
      this.userService.userData &&
      this.userService.userData.isAdmin
    ) {
      this.typeUser = StatTypeUserEnum.ADMIN;
    }
  }

  consultMonthActivity() {
    console.log(this.periodValidation.value);
  }

  displayValue () {
    this.displayDetail = true
  }
}
