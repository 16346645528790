<ul *ngIf="this.typeUser === this.type.ADMIN" class="nav nav-tabs mb-4">
  <li class="nav-item">
    <a routerLink="/admin/stat/activity"
      routerLinkActive="active"
      class="nav-link">Activités</a>
  </li>
  <li class="nav-item">
    <a routerLink="/admin/stat/validate"
      routerLinkActive="active"
      class="nav-link">Relevés</a>
  </li>
</ul>

<ul *ngIf="this.typeUser === this.type.SPEAKER" class="nav nav-tabs mb-4">
  <li class="nav-item">
    <a routerLink="/stat/activity"
      routerLinkActive="active"
      class="nav-link">Activité</a>
  </li>
  <li class="nav-item">
    <a routerLink="/stat/validate"
      routerLinkActive="active"
      class="nav-link">Validation de l'activité</a>
  </li>
</ul>
