import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPictureComponent } from 'projects/speaker-platform/src/app/atoms/user-picture/user-picture.component';
import { Sizes } from 'projects/speaker-platform/src/app/types/sizes.enum';
import { Speaker } from 'projects/speaker-platform/src/app/types/speaker';
import { PlanningEventReasons } from '../../types/planning-event-reasons.enum';

@Component({
  selector: 'app-speaker-list-item',
  standalone: true,
  imports: [CommonModule, UserPictureComponent],
  templateUrl: './speaker-list-item.component.html',
  styleUrls: ['./speaker-list-item.component.scss']
})
export class SpeakerListItemComponent {
  sizesEnum = Sizes;
  @Input() selected: boolean = false;
  @Input() name: string = '';
  @Input() status!: PlanningEventReasons;
  @Input() speaker!: Speaker;
  @Output() selectEvent = new EventEmitter<any>();

  onSelect(speaker: Speaker): void {
    this.selectEvent.emit(speaker);
  }

  statutDisplay () {
    const now = new Date();
    if (now <= new Date(this.speaker.date_fin_statut) && now >= new Date (this.speaker.date_debut_statut)) {
     return this.speaker.statut
    }
    return null;
  }
}
