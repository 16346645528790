import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MenuCategoryComponent } from 'projects/speaker-platform/src/app/atoms/menu-category/menu-category.component';
import { MenuItemComponent } from 'projects/speaker-platform/src/app/atoms/menu-item/menu-item.component';
import { AuthService } from 'projects/speaker-platform/src/app/services/auth.service';
import {NotificationService} from "../../services/notification.service";
import {MercureService} from "../../services/mercure.service";
import {UserService} from "@common/services/user.service";

@Component({
  standalone: true,
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  imports: [MenuCategoryComponent, MenuItemComponent, CommonModule],
})
export class MainMenuComponent implements OnInit {
  constructor(
    private authService: AuthService,
    protected notificationService: NotificationService,
    private mercureService: MercureService,
    private userService: UserService,
  ) {}
  @Input() isAdmin: boolean = false;
  @Input() userRoles: Set<string> = new Set();

  hasRoleUserInterne: boolean = false;
  hasRoleAdmin: boolean = false;
  hasRoleComedienneInterne: boolean = false;
  hasRoleComedienneUtilisateur: boolean = false;
  hasRoleComedienneAdmin: boolean = false;

  ngOnInit(): void {
    this.hasRoleUserInterne = this.userRoles.has('ROLE_USER_INTERNE');
    this.hasRoleAdmin = this.userRoles.has('ROLE_ADMIN');
    this.hasRoleComedienneInterne = this.userRoles.has('ROLE_COMEDIENNE_INTERNE');
    this.hasRoleComedienneUtilisateur = this.userRoles.has('ROLE_COMEDIENNE_UTILISATEUR');
    this.hasRoleComedienneAdmin = this.userRoles.has('ROLE_COMEDIENNE_ADMIN');

    this.notificationService.actualiseCount()

    this.notificationService.getAllUnreadConversation().subscribe();

    this.mercureService.connect(`/user/${this.userService.token.id}/hasUnreadConversation`).subscribe({
      next: data => {
        this.notificationService.count = data;
      }
    })
  }

  logout(): void {
    this.authService.logout();
  }
}
