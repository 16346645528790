<div class="dashboard container">
  <div class="row">
    <div class="col-6">
      <h2>Status comédienne</h2>
      <div class="form-group">
        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="soustitre" maxlength="50"
               placeholder="Vos envies pour la prochaines séances (simple, double, ...)" [(ngModel)]="statut" >
        <small id="soustitre" class="form-text text-muted ms-1">Le nombre de caractères est limité à 50.</small>
      </div>
    </div>
    <div class="col-2">
      <h2>Date début</h2>
      <input
        class="form-control" type="date" name="dateDebut" id="dateDebut" [min]="today" [(ngModel)]="dateStartStatus"/>
    </div>
    <div class="col-2">
      <h2>Date Fin</h2>
      <input class="form-control" type="date" name="dateFin" id="dateFin" [min]="dateStartStatus"  [(ngModel)]="dateEndStatus"/>
    </div>
    <div class="col-2 validStatus">
      <h2>Valider ?</h2>
      <button type="button" (click)="submitStatus()" [disabled]="dateEndStatus === '' || statut === '' || dateStartStatus === ''"
              class="btn btn-primary"><i class="fa-solid fa-floppy-disk ps-3 pe-3"></i></button>
    </div>
    <hr class="m-2">
  </div>

  <div class="row mt-3">
    <div class="col-7">
      <h2>
        Cette semaine
        <span
          >du
          {{
            this.format(
              this.weekStartDate,
              this.weekStarsAndEndsInSameMonth ? "dd" : "dd MMMM"
            )
          }}
          au {{ this.format(this.weekEndDate, "dd MMMM yyyy") }}</span
        >
      </h2>
      <app-weekly-planning-preview
        *ngIf="this.fetchEventsComplete"
        [week]="this.eachDayOfInterval(this.weekInterval)"
        [planningEvents]="this.eventsInWeek"
      />
    </div>
    <div class="col-5">
      <h2>Séances en cours</h2>
      <div class="dashboard__ongoingseances papercard">
        <div *ngIf="this.ongoingSeances.length === 0">
          Aucune séance reçue pour le moment
        </div>
        <div class="dashboard__ongoingseances__item lh-lg" *ngFor="let seance of this.ongoingSeances">
          Séance {{ seance.id }} - {{ seance.mode === 'TRADUCTION' ? 'Traductions' : 'Prises voix' }} <br />
          Attribuée le
          {{ seance.date_creation | date : "dd/MM/yyyy" }} <br />
          {{ seance.compteur_done }} textes {{ seance.mode === 'TRADUCTION' ? 'traduits' : 'livrés' }} sur
          {{ seance.compteur_todo }}
          <div *ngIf="seance.mode === 'VOCAL' && seance._stdby && seance._stdby > 0">
            <i class="fa-solid fa-triangle-exclamation me-1"></i>
            {{ seance._stdby }} texte(s) en standby
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
