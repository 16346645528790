import { EventEmitter, Injectable } from '@angular/core';
import { AtsappService } from './atsapp.service';
import { Observable, filter, from, map } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserData } from '@common/types/user-data.interface';
import { format, isWithinInterval, startOfToday } from 'date-fns';
import { PlanningEventReasons } from '@common/types/planning-event-reasons.enum';
import { enUS } from 'date-fns/locale';

export function tokenGetter(): string | null {
  return localStorage.getItem('jwt');
}


@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private atsappService: AtsappService,
    private jwt: JwtHelperService
  ) {
    const t = tokenGetter();
    if (t) {
      this.token = this.jwt.decodeToken(t);
    }
  }

  userData!: UserData;
  token: any = null;
  currentUserStatus!: PlanningEventReasons;
  userRoles: Set<string> = new Set();
  userDataLoaded: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  checkTokenValidity() {
    if (tokenGetter()) {
      if (this.jwt.isTokenExpired(tokenGetter())) {
        localStorage.removeItem('jwt');
        localStorage.removeItem('mercureAuthorization');
        window.location.href = '/authentication/#/login';
      } else {
        //@ts-expect-error
        const decodedToken = this.jwt.decodeToken(tokenGetter());
        this.getUtilisat(decodedToken.utilisat).subscribe();
      }
    } else {
      window.location.href = '/authentication/#/login';
    }
  }

  getMercureAuthorizationToken(): string | null {
    return window.localStorage.getItem('mercureAuthorization');
  }

  getUtilisat(trigramme: string) {
    return new Observable((observer) => {
      this.atsappService.getUtilisat(trigramme).subscribe({
        next: (res: any) => {
          this.userData = {
            libelle: res.us_libelle,
            speakerId: -1,
            codespeak: '',
            trigramme: res.us_utilisateur,
            login: res.us_email,
            phone: res.us_tel1,
            isAdmin: true,
            roles: this.token.roles,
          };

          this.userRoles = new Set(this.token.roles);

          this.userData.isAdmin = this.userRoles.has('ROLE_COMEDIENNE_ADMIN') || this.userRoles.has('ROLE_ADMIN')

          if (this.userRoles.has('ROLE_COMEDIENNE_INTERNE') && !this.userRoles.has('ROLE_ADMIN')) {
            this.getAdminSpeaker(res.us_email)
          } else {
            this.userDataLoaded.emit(true)
          }
        },
      });
    });
  }

  getAdminSpeaker(email: string) {
    this.atsappService.getSpeakerByEmail(email).subscribe({
      next: (res: any) => {
        if (this.userData) {
          this.userData.speakerId = res.id;
          this.userData.codespeak = res.codespeak;
          this.userData.disponibilite = res.disponibilite;
        }
        this.userDataLoaded.emit(true)
      },
    })
  }

  getSpeaker(email: string) {
    return new Observable((observer) => {
      this.atsappService.getSpeakerByEmail(email).subscribe({
        next: (res: any) => {
          this.getCurrentUserStatus(res.id);
          this.userData = {
            libelle: `${res.prenom} ${res.nom}`,
            speakerId: res.id,
            codespeak: res.codespeak,
            trigramme: res.trigramme,
            login: res.email,
            disponibilite: res.disponibilite,
            isAdmin: false,
            roles: this.token.roles,
          };

          this.userRoles = new Set(this.token.roles);
          this.userDataLoaded.emit(true);
        },
      });
    });
  }

  intervalCheck(start: string, end: string): boolean {
    return isWithinInterval(startOfToday(), {
      start: new Date(start).setHours(0, 0),
      end: new Date(end).setHours(0, 0),
    });
  }

  getCurrentUserStatus(id: number) {
    this.atsappService.getPlanningEventsByComedienne(id).subscribe({
      next: (res: any) => {
        const currentUserStatus = res.filter((evt: any) =>
          this.intervalCheck(evt.date_debut, evt.date_fin)
        );

        const isCurrentUserAvailable = this.userData?.disponibilite?.includes(
          format(startOfToday(), 'EEE', { locale: enUS })
        );

        if (currentUserStatus.length > 0) {
          // En toute logique, on est censé avoir 0 ou 1 événement
          this.currentUserStatus = currentUserStatus[0].raison;
          return;
        }

        if (isCurrentUserAvailable) {
          this.currentUserStatus = PlanningEventReasons.SEANCE;
        } else {
          this.currentUserStatus = PlanningEventReasons.NO_SEANCE;
        }
      },
    });
  }

  get user(): UserData {
    return this.userData;
  }

  set user(userData: UserData) {
    this.userData = userData;
  }

  get userStatus(): PlanningEventReasons {
    return this.currentUserStatus;
  }

  set userStatus(status: PlanningEventReasons) {
    this.currentUserStatus = status;
  }
}
