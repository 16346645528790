<div class="statslist page container">
  <div class="statslist__header">
    <div class="row">
      <div class="col-12">
        <app-statistic-menu [typeUser]="this.typeUser"></app-statistic-menu>
      </div>
    </div>
  </div>
  <form [formGroup]="periodValidation" (submit)="consultMonthActivity()">
    <div class="row align-items-end">
      <div class="col-4">
        <label class="form-label" for="period">Recherche sur le mois de :</label>
        <input class="form-control" type="month" id="period" formControlName="period" name="period"/>
      </div>
      <div class="col-6"></div>
      <div class="col-2">
        <button class="btn btn-primary" type="submit" [disabled]="periodValidation.invalid" >
          Rechercher
        </button>
      </div>
    </div>
  </form>

  <div class="row mt-5">
    <div class="col-12">
      <table class="statslist__header table">
        <thead>
        <tr>
          <th>Comédienne</th>
          <th>Langue(s)</th>
          <th>Activité validée ?</th>
          <th>Date de validation</th>
          <th>Payé ?</th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let ligne of test" (click)="this.displayDetail = true">
            <td>{{ligne.test}}</td>
            <td>{{ligne.value}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  choix du mois de l'année <br>
  liste des comédienne qui ont une activité sur le mois selectionné, la ou les langue(s) des commediennes,<br>
  si l'activité a été validé ou non, la date de validation, une option de si la COMPTA considère comme payé ou pas (requête POST) <br>
  Et eventuellement un export xls pour la compta
</div>

<div *ngIf="displayDetail" class="detailStat container mt-4 mb-2">
Lorem ipsum dolor sit amet, consectetur adipisicing elit. Esse ex facilis illo nisi nobis, omnis placeat provident quibusdam quisquam? Asperiores dolorum earum explicabo, harum impedit nostrum praesentium provident repudiandae tempore.
</div>

