<div class="mainmenu">
  <ng-container
    *ngIf="this.hasRoleComedienneUtilisateur && !this.hasRoleComedienneInterne"
  >
    <app-menu-category label="Activité" />
    <ul class="mainmenu__list">
      <app-menu-item
        icon="microphone"
        label="Mes textes à enregistrer"
        destination="textes-a-enregistrer"
      />
      <app-menu-item
        icon="globe"
        label="Mes textes à traduire"
        destination="textes-a-traduire"
      />
      <app-menu-item
        icon="paper-plane"
        label="Livrer des enregistrements"
        destination="livrer-des-enregistrements"
      />
      <app-menu-item
        icon="radio"
        label="Séance On Air"
        destination="on-air"
      />
    </ul>
    <app-menu-category label="Informations" />
    <ul class="mainmenu__list">
      <app-menu-item
        icon="calendar-days"
        label="Mon planning"
        destination="planning"
      />
      <app-menu-item
        icon="chart-simple"
        label="Mes statistiques"
        destination="stat/activity"
      />
    </ul>
  </ng-container>

  <ng-container *ngIf="this.hasRoleComedienneInterne">
    <app-menu-category label="Mon&nbsp;activité" />
    <ul class="mainmenu__list">
      <app-menu-item
        icon="microphone"
        label="Mes textes à enregistrer"
        destination="textes-a-enregistrer"
      />
      <app-menu-item
        icon="globe"
        label="Mes textes à traduire"
        destination="textes-a-traduire"
      />
      <app-menu-item
        icon="paper-plane"
        label="Livrer des enregistrements"
        destination="livrer-des-enregistrements"
      />
    </ul>
    <app-menu-category label="Mes&nbsp;informations" />
    <ul class="mainmenu__list">
      <app-menu-item
        icon="calendar-days"
        label="Mon planning"
        destination="planning"
      />
    </ul>
    <app-menu-category label="Administration" />
    <ul class="mainmenu__list">
      <app-menu-item
        icon="calendar-days"
        label="Planning général"
        destination="admin/planning"
      />
      <app-menu-item
        icon="microphone"
        label="Gestion des prises voix"
        destination="admin/gestion-prises-voix/textes-a-envoyer"
      />
      <app-menu-item
        icon="globe"
        label="Gestion des traductions"
        destination="admin/gestion-traductions/textes-a-envoyer"
      />
      <app-menu-item
        icon="sliders"
        label="Gestion des séances"
        destination="admin/gestion-seances"
      />
      <app-menu-item
        icon="people-roof"
        label="Gestion des comédien·ne·s"
        destination="admin/modifier-profil"
      />
    </ul>
  </ng-container>

  <app-menu-category label="Messagerie" />
    <ul class="mainmenu__list">
      <app-menu-item
        icon="comments"
        label="Mes messages"
        destination="messagerie"
        [countMessage]="this.notificationService.count"
        />
    </ul>

  <ng-container *ngIf="this.hasRoleComedienneAdmin && !this.hasRoleComedienneInterne">
    <app-menu-category label="Administration" />
    <ul class="mainmenu__list">
      <app-menu-item
        icon="calendar-days"
        label="Planning général"
        destination="admin/planning"
      />
    </ul>
    <app-menu-category label="Textes" />
    <ul class="mainmenu__list">
      <app-menu-item
        icon="microphone"
        label="Gestion des prises voix"
        destination="admin/gestion-prises-voix/textes-a-envoyer"
      />
      <app-menu-item
        icon="globe"
        label="Gestion des traductions"
        destination="admin/gestion-traductions/textes-a-envoyer"
      />
      <app-menu-item
        icon="sliders"
        label="Gestion des séances"
        destination="admin/gestion-seances"
      />
    </ul>
    <app-menu-category label="Comédien·ne·s" />
    <ul class="mainmenu__list">
      <app-menu-item
        icon="people-roof"
        label="Gestion des comédien·ne·s"
        destination="admin/modifier-profil"
      />
      <app-menu-item
        icon="chart-simple"
        label="Gestion des statistiques"
        destination="admin/stat/activity"
      />
    </ul>
  </ng-container>


  <ng-container *ngIf="this.hasRoleUserInterne && !this.hasRoleComedienneAdmin">
    <app-menu-category label="Administration" />
    <ul class="mainmenu__list">
      <app-menu-item
        icon="calendar-days"
        label="Planning général"
        destination="admin/planning"
      />
    </ul>
  </ng-container>

  <ul class="mainmenu__list">
    <app-menu-item
      icon="person-running"
      label="Se déconnecter"
      type="button"
      [logout]="true"
      (clickEvent)="this.logout()"
    />
  </ul>
</div>
