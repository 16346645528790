import { Component } from '@angular/core';
import {StatisticMenuComponent} from "../../molecules/statistic-menu/statistic-menu.component";
import {StatTypeUserEnum} from "../../types/StatTypeUser.enum";
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";

@Component({
  selector: 'app-statistic-speaker-validate',
  templateUrl: './statistic-speaker-validate.component.html',
  styleUrls: ['./statistic-speaker-validate.component.scss'],
  standalone: true,
  imports: [
    StatisticMenuComponent,
    ReactiveFormsModule
  ]
})
export class StatisticSpeakerValidateComponent {
  constructor(
    private fb: FormBuilder,
  ) {
  }

  typeUser: StatTypeUserEnum = StatTypeUserEnum.SPEAKER
  periodValidation = this.fb.group({
    period: ['', [Validators.required]],
    files: [[], [Validators.required]],
  })

  loadedJustificatif(event: any) {
    this.periodValidation.controls.files.setValue(event.target.files)
  }

  sendJustificatifs() {
  }

}
