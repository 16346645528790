import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable({ providedIn: 'root' })
export class Constants {
  public readonly ATS_INTERNE_URL = environment.interneUrl;
  public readonly ATS_SPEAKER_PLATFORM_BASE_URL = environment.speakerPlatformBaseUrl;
  public readonly ATS_PUBLIC_QRCODE_BASE_URL = environment.publicQrcodeBaseURL;
  public readonly ATSAPP_API_URL = environment.apiBaseUrl + '/api';
  public readonly ATSAPP_AUTH_API_URL =
    environment.apiBaseUrl + '/auth';
  public readonly ATSAPP_QRCODE_URL = environment.publicQrcodeURL;
  public readonly ATSAPP_QRCODE_COLLECTION_URL = environment.publicQrcodeCollectionURL;
  public readonly ATSAPP_MERCURE_URL = environment.mercureUrl;
}
