<div class="statslist page container">
  <div class="statslist__header">
    <div class="row">
      <div class="col-12">
        <app-statistic-menu [typeUser]="this.typeUser"></app-statistic-menu>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-statistic-filter
          [speakersList]="this.speakersList"
          [languesList]="this.languesList"
          [phasesVocalesList]="this.phasesVocalesList"
          [typesMessageList]="this.typesMessageList"
          [isLoading]="this.isLoading"
          [isAdmin]="this.typeUser"
          [user]="this.userService.userData"
          (search)="this.getStatForSpk($event)"
        ></app-statistic-filter>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <table class="statslist__header table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Mode seance</th>
              <th>Statut séance</th>
              <th>Langue</th>
              <th>Nb séance</th>
            </tr>
          </thead>
          <tbody>
            <tr>

            </tr>
          </tbody>

        </table>
      </div>
    </div>
  </div>
</div>
