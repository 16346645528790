import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {UserService} from "@common/services/user.service";


@Component({
  selector: 'app-history-message',
  standalone: true,
  templateUrl: './history-message.component.html',
  imports: [
    CommonModule,
  ],
  styleUrls: ['./history-message.component.scss']
})
export class HistoryMessageComponent {

  constructor(
    protected usrService: UserService,
  ) {}

  @Input() histories: any;
  @Input() activeUsers: any;
  @Input() selectedConversation: any;
  @Output() conversationSelected = new EventEmitter<any>();

  selectConversation(conversation:any) {
    this.conversationSelected.emit(conversation);
    // this.selectedConversation.getLastMsg()
  }

  getName(detail : any) {

    let name : any[] = []
    detail?.user_groups?.forEach((grp: any ) => {
      name.push(grp.name);
    })


    detail?.conversationuser?.forEach((convUser: { user: { id: { toString: () => any; }; }; }) => {
      const utilisateur = this.activeUsers?.find((u: { ID: any; }) => u.ID === convUser?.user.id.toString());
      if (utilisateur && utilisateur.ID !== this.usrService.token.id.toString()) {
        name.push(utilisateur.NAME);
      }
    });
    return name.join(', ');
  }

  getLastMsg(detail : any) {
    if (detail.messages && detail.messages.length > 0) {
      return detail?.messages?.reduce((prev: { date_creation: string | number | Date; }, current: { date_creation: string | number | Date; }) => {
        return new Date(prev.date_creation) > new Date(current.date_creation) ? prev : current;
      });
    }
    else {
      return 'Pas encore de messages.'
    }
  }

  filterStatus(status: any) {
    return status?.find((stat: any) =>
      stat.user.id === this.usrService.token.id
    )
  }

  trackByFn(index: number, item: any) {
    return item.id
  }
}
