import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, filter, map, takeUntil } from 'rxjs';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
} from '@angular/router';
import { MainMenuComponent } from './molecules/main-menu/main-menu.component';
import { SidebarComponent } from './organisms/sidebar/sidebar.component';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { JwtHelperService } from '@auth0/angular-jwt';
import { tokenGetter } from 'projects/speaker-platform/src/main';
import { ToastsComponent } from './atoms/toasts/toasts.component';
import { UserService } from '@common/services/user.service';
import {NotificationService} from "./services/notification.service";
@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    MainMenuComponent,
    SidebarComponent,
    RouterOutlet,
    CommonModule,
    ToastsComponent,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private jwtHelper: JwtHelperService,
    protected userService: UserService,
    private notificationService: NotificationService,
  ) {}

  fullPage: boolean = false;
  pageTitle: string = '';
  sidebarCollapsed: boolean = false;

  token: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  // userData: any;

  ngOnInit(): void {

    this.notificationService.actualiseCount()
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data) {
              return child.snapshot.data;
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((customData: any) => {
        this.checkTokenValidity();
        this.getToken();
        this.fullPage = customData.fullPage;
        this.pageTitle = customData.title;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  checkTokenValidity() {
    if (this.jwtHelper.isTokenExpired(tokenGetter()) || this.token === null) {
      localStorage.removeItem('jwt');
      localStorage.removeItem('mercureAuthorization');
      const isResetPasswordRoute = this.router.url.startsWith('/reset-password/') &&
        this.activatedRoute.snapshot.params['token'] &&
        this.activatedRoute.snapshot.params['organisation'];
      this.router.url !== '/reinitialiser-mdp' && isResetPasswordRoute && this.router.navigate(['/login']);
    }
  }

  getToken() {
    const token = tokenGetter();
    const isResetPasswordRoute = this.router.url.startsWith('/reset-password/')
    if (token && !this.userService.user) {
      this.token = this.jwtHelper.decodeToken(token);
      if (
        this.token.roles.includes('ROLE_COMEDIENNE_ADMIN') ||
        this.token.roles.includes('ROLE_ADMIN') ||
        this.token.roles.includes('ROLE_COMEDIENNE_INTERNE') ||
        this.token.roles.includes('ROLE_USER_INTERNE')
      ) {
        this.userService
          .getUtilisat(this.token.utilisat)
          .pipe(takeUntil(this.destroy$))
          .subscribe();

      } else {
        this.userService
          .getSpeaker(this.token.login)
          .pipe(takeUntil(this.destroy$))
          .subscribe();
      }
    }
    if (!token && this.router.url !== '/reinitialiser-mdp' && !isResetPasswordRoute) {
      this.router.navigate(['/login']);
    }

    if (token && this.token.roles.includes('ROLE_USER_INTERNE') && !this.token.roles.includes('ROLE_COMEDIENNE_ADMIN') && !this.router.url.startsWith('/admin/planning') && this.router.url !== '/login') {
      this.router.navigate(['/admin/planning']);
    }
  }

  title = 'speaker-platform';
}
