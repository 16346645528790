import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {StatTypeUserEnum} from "../../types/StatTypeUser.enum";

@Component({
  selector: 'app-statistic-menu',
  templateUrl: './statistic-menu.component.html',
  standalone: true,
  styleUrls: ['./statistic-menu.component.scss'],
  imports: [CommonModule, RouterModule]
})
export class StatisticMenuComponent {
  @Input() typeUser: StatTypeUserEnum = StatTypeUserEnum.SPEAKER;
  type = StatTypeUserEnum;

}
