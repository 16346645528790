import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService} from "../../../../common/services/abstract.service";

import {ToastService} from './toast.service';
import {Router} from "@angular/router";
import {catchError} from "rxjs";
import {Constants} from "@common/config/constants";

@Injectable({
  providedIn: 'root'
})
export class MessagingService extends AbstractService{


  constructor(
    protected http: HttpClient,
    protected constants: Constants,
    toastService: ToastService,
    router : Router
  ) {
    super(toastService, router );}

  createConversation(userId: number,  isGroup: boolean, message: string, titre?: string) {
    // mise de l'id en Array car le back attends un tableau
    let groupsID : any[] = []
    let usersID : any[] = []
    if (isGroup) {
       groupsID = [userId];
    }
    else {
       usersID = [userId];
    }
    const body = {
      usersID,
      groupsID,
      message,
      titre
    };

    return this.http.post(`${this.constants.ATSAPP_API_URL}/mercure/conversation/create`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }

  getCurrentUser() {
    return this.http.get(`${this.constants.ATSAPP_API_URL}/mercure/conversations/currentUser`).
    pipe(catchError(err => this.handleError(err)));
  }

  sendNewMessage(idConversation: number, message: string) {
    const body = {
      message: message
    };
    return this.http.post(`${this.constants.ATSAPP_API_URL}/mercure/message/send/${idConversation}`, body);
  }

  setReadConversation(idConversation: number) {
    return this.http.get(`${this.constants.ATSAPP_API_URL}/mercure/conversations/setConvToRead/${idConversation}`)
      .pipe(catchError(err => this.handleError(err)));
  }

  createGroupConversation(usersID: number[], groupsID: number[], message: string, titre?: string) {
    const body = {
      usersID,
      groupsID,
      message
    }
    return this.http.post(`${this.constants.ATSAPP_API_URL}/mercure/conversationMultiple/create`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }

  setNewPreferenceMail(idConversation: number) {
    return this.http.get(`${this.constants.ATSAPP_API_URL}/mercure/conversations/switchPreferenceNotification/${idConversation}`).
    pipe(catchError(err => this.handleError(err)));
  }

}
