<div class="conversation">
  <div class="row head-conversation">
    <div class="new" *ngIf="selectedConversation === null && activeUsers !== null">
      <angular2-multiselect [data]="activeUsers" [(ngModel)]="selectedReceivers"
        [settings]="dropdownSettings"
      ></angular2-multiselect>
    </div>
    <div class="destinataire" *ngIf="selectedConversation !== null">
      {{ this.name.join(', ')}}

      <i class="ps-3 fa-solid" style="cursor: pointer" [ngClass]="{'fa-bell-slash': !this.selectedConversation.is_active_notifications,
      'fa-bell': this.selectedConversation.is_active_notifications}" (click)="switchNotif()"
         [ngbTooltip]="selectedConversation.is_active_notifications ? 'Désactiver les notifications ?' : 'Activer les notifications ?'"></i>
      <hr>
    </div>
  </div>
  <div class="row messages">
    <div class="d-flex align-items-center"  *ngIf="selectedConversation?.messages.length === 0 || selectedConversation === null">
      <div class="col-12 text-center">
        <p><i>Vous retrouverez votre historiques de message ici.</i></p>
      </div>
    </div>
    <div class="histo" #containerHisto *ngIf="selectedConversation?.messages.length !== 0 && selectedConversation !== null">
      <ng-container *ngFor="let msg of this.selectedConversation?.messages">
        <div class="desc" [ngClass]="{'otherDesc' : msg.user.id === usrService.token.id}">{{nameDesc(msg)?.NAME}}  {{msg.date_creation | date : 'dd/MM/YYYY HH:mm'}}</div>
        <div class="message" [innerHTML]="msg.contenu | addJumpLine" [ngClass]="{'otherMsg' : msg.user.id !== usrService.token.id}" [ngbTooltip]="msg.date_creation | date : 'dd/MM HH:mm'">
          {{msg.contenu}}
        </div>
      </ng-container>

    </div>
  </div>
  <div class="row send-new">
    <div class="input-group mb-3">
      <textarea rows="3" [(ngModel)]="textMessage"  class="form-control message-text" placeholder="Saisissez votre message ici :"
          (keyup.enter)="this.selectedConversation === null ? createNewConversation() : sendMessage()" ></textarea>
      <button class="btn btn-primary" type="button" id="button-addon2"
          [ngbTooltip]="this.selectedConversation === null ? 'Créer la conversation.' :'Envoyer le message.' "
          (click)="this.selectedConversation === null ? createNewConversation() : sendMessage()"
          [disabled]="(textMessage === '' || textMessage.trim() === '') || loadingMessage ||
          (selectedConversation === null && selectedReceivers.length === 0)">
        <i class="fa-solid fa-paper-plane" ></i>
      </button>
    </div>
  </div>
</div>


