import { Component } from '@angular/core';

@Component({
  selector: 'app-seance-on-air',
  standalone: true,
  templateUrl: './seance-on-air.component.html',
  styleUrls: ['./seance-on-air.component.scss']
})
export class SeanceOnAirComponent {

}
