import {Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core';
import {AngularMultiSelectModule} from "angular2-multiselect-dropdown";
import {FormsModule} from "@angular/forms";
import {NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {MessagingService} from "../../services/messaging.service";
import {CommonModule} from "@angular/common";
import {UserService} from "@common/services/user.service";
import {AddJumpLinePipe} from "../../pipes/add-jump-line.pipe";

@Component({
  selector: 'app-conversation-messaging',
  standalone: true,
  templateUrl: './conversation-messaging.component.html',
  imports: [
    CommonModule,
    AngularMultiSelectModule,
    FormsModule,
    NgbTooltip,
    AddJumpLinePipe,
  ],
  styleUrls: ['./conversation-messaging.component.scss']
})
export class ConversationMessagingComponent implements OnChanges {
  selectedReceivers: any = [];
  dropdownSettings = {
    singleSelection: false,
    text:"Choisissez un ou plusieurs destinataires pour votre message.",
    selectAllText:'Tout sélectionner',
    unSelectAllText:'Tout désélectionner',
    enableSearchFilter: true,
    searchPlaceholderText: 'Recherchez votre destinataire.',
    labelKey: 'NAME',
    primaryKey: 'ID'
  };

  @Input() activeUsers: any;
  @Input() selectedConversation: any;

  protected name: any[] = [];
  protected textMessage: string = '';
  protected loadingMessage: boolean = false;
  @ViewChild('containerHisto') private containerHisto: ElementRef | undefined ;


  constructor(
    private messagingService: MessagingService,
    protected usrService: UserService,
  ) {
  }


  ngOnChanges() {
    this.name = [];

    if (this.selectedConversation !== null) {
      // console.log(this.selectedConversation)
      const detail = this.selectedConversation;

      detail?.user_groups?.forEach((grp: any ) => {
        this.name.push(grp.name);
      })

      detail?.conversationuser?.forEach((convUser: { user: { id: { toString: () => any; }; }; }) => {
        const utilisateur = this.activeUsers?.find((u: { ID: any; }) => u.ID === convUser?.user.id.toString());
        if (utilisateur && utilisateur.ID !== this.usrService.token.id.toString()) {
          this.name.push(utilisateur.NAME);
        }
      });

      setTimeout(() => {
        this.scrollToBottom()
      },50)

    }
  }

  createNewConversation() {
    if (this.selectedConversation === null) {
      this.loadingMessage = true;

      if (this.selectedReceivers.length > 1) {
        let groupId : number[] = []
        let userId : number[] = []

        this.selectedReceivers.forEach((receiver :any) => {
          receiver.GROUP ? groupId.push(receiver.ID) : userId.push(receiver.ID);
        })

        this.messagingService.createGroupConversation(userId,groupId, this.textMessage).subscribe({
          complete: () => {
            this.selectedReceivers = [];
            this.textMessage = '';
            this.loadingMessage = false;
          }
        })
      }

      if (this.selectedReceivers.length === 1) {
        const usr = this.selectedReceivers[0]
        this.messagingService.createConversation(usr.ID, usr.GROUP, this.textMessage).subscribe({
          complete: () => {
            this.selectedReceivers = []
            this.textMessage = '';
            this.loadingMessage = false;
          }
        })
      }
    }
  }

  sendMessage (idConversation? : number) {

    if (idConversation === undefined) idConversation = this.selectedConversation.id
    const txtMsg = this.textMessage
    this.textMessage = ''

    this.messagingService.sendNewMessage(idConversation!, txtMsg).subscribe({
    next: () => {
      this.scrollToBottom()
    },
    })
  }

  switchNotif() {
    this.messagingService.setNewPreferenceMail(this.selectedConversation.id).subscribe({
      complete: () => {
        this.selectedConversation.is_active_notifications = !this.selectedConversation.is_active_notifications
      }
    })
  }

  scrollToBottom(): void {
    try {
      this.containerHisto!.nativeElement.scrollTop = this.containerHisto?.nativeElement?.scrollHeight;
    } catch (err) {
      console.error('Erreur de scrolling :', err);
    }
  }

  nameDesc(msg:any) {
   return this.activeUsers.find((u: { ID: any; }) => u.ID === msg.user.id.toString());
  }

}
