<div
  class="speakerlistitem"
  [ngClass]="{ 'speakerlistitem--selected': this.selected }"
  (keydown.enter)="this.onSelect(this.speaker)"
  (click)="this.onSelect(this.speaker)"
>
  <div class="d-flex align-items-center">
    <div class="col-2">
    <app-user-picture
      [picture]="this.speaker.photo"
      [size]="this.sizesEnum.SMALL"
      [status]="this.status"
      [firstName]="this.speaker.prenom"
      [lastName]="this.speaker.nom"
      [codespeak]="this.speaker.codespeak"
    />
    </div>
    <div class="col-9">
      <div class="row">
        <div class="speakerlistitem__name ms-3">
         {{ this.name }}
        </div>
      </div>
      <div class="row legendStatus">
        {{ statutDisplay() }}
      </div>
    </div>

  </div>
</div>

  <!-- picture="assets/userpictures/v3_0993233.jpg" -->
