<div class="messagerie page container">
  <div class="row mt-1"  *ngIf="!this.loaderGlobal">
    <div class="col-3 left-panel">
      <div class="row">
        <div class="col-12">
          <button type="button" class="btn btn-primary" (click)="newConversation()">
            <i class="fa-solid fa-comment-medical me-2"></i>
            Nouvelle conversation
          </button>
        </div>
      </div>
      <div class="row mt-3 right-panel">
        <app-history-message
          [activeUsers]="activeUsers"
          [histories]="allConversations"
          [selectedConversation]="conversation"
          (conversationSelected)="this.onSelectedConversation($event)"
        />
      </div>
    </div>
    <div class="col-9 ps-0">
      <app-conversation-messaging
        [activeUsers]="activeUsers"
        [selectedConversation]="conversation"
      />
    </div>
  </div>
  <div *ngIf="this.loaderGlobal">
    <app-loader></app-loader>
  </div>
</div>

