<div class="statslist page container">
  <div class="statslist__header">
    <div class="row">
      <div class="col-12">
        <app-statistic-menu [typeUser]="this.typeUser"></app-statistic-menu>
      </div>
    </div>
  </div>
  <form [formGroup]="periodValidation" (submit)="sendJustificatifs()">
    <div class="row mb-3 align-items-end">
      <div class="col-3">
        <label class="form-label" for="period">Mois à valider :</label>
        <input class="form-control" type="month" id="period" formControlName="period" name="period"/>
      </div>
      <div class="col-6">
        <label for="formFileMultiple" class="form-label">Fichier(s) justificatif :</label>
        <input class="form-control" (change)="loadedJustificatif($event)" type="file" id="formFileMultiple" multiple>
      </div>
      <div class="col-2"></div>
      <div class="col-1 p-0">
        <button class="btn btn-primary" type="submit" [disabled]="periodValidation.invalid" >
          Valider
        </button>
      </div>
    </div>
  </form>
</div>



